@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  background: colors.$white;
  color: colors.$default-text;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: grid;
  grid-template-areas:
    'ptimage .'
    'ptimage ptname'
    'ptimage ptdesc'
    'ptimage ptprice';
  grid-template-columns: max-content auto;
  padding: vars.$nodeSpacing;
  column-gap: vars.$nodeSpacing;
  row-gap: vars.$nodeSpacing;
  position: relative;
  text-decoration: none;
  height: 100%;
  // margin-bottom: $gutter;

  @media screen and (min-width: vars.$extra-large) {
    grid-template-areas:
      'ptimage ptimage'
      'ptadultswitch ptadultswitch'
      'ptname ptname'
      'ptdesc ptdesc'
      'ptprice .';
    grid-template-columns: auto auto;
    grid-template-rows: max-content max-content max-content max-content auto;
    row-gap: 0;
    // column-gap: $nodeSpacing;
  }
  // &:focus,&:focus-within {
  //   outline: auto 5px $primary-color;
  // }
  &:hover,
  &.selected {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

    .wishlistBtn {
      opacity: 1;
    }

    .wishlistSelectedBtn {
      opacity: 1;
    }

    @media screen and (min-width: vars.$extra-large) {
      .quickView {
        opacity: 1;
      }
      .productImage img {
        // height: 100px;
        transform: scale(0.66, 0.66) translateY(-35px);
        // margin-bottom: 55px;
      }
      // .productPrice {
      //   margin-top: $nodeSpacing;
      // }
    }
  }
}

.goToPdp {
  display: none;
}

.oldLayout {
  display: grid;
  grid-template-areas:
    'ptimage'
    'ptadultswitch'
    'ptname'
    'ptdesc'
    'ptprice'
    'ptshop';
  grid-template-columns: auto;
  grid-template-rows: max-content max-content auto max-content max-content;
  padding: vars.$nodeSpacing;
  row-gap: 0;
  position: relative;
  text-decoration: none;
  // margin-bottom: $gutter;

  .quickView {
    display: none;
  }

  .productName {
    margin-bottom: vars.$nodeSpacing;
  }
  .productShop {
    .productShopName {
      color: colors.$secondary-color;
    }
  }
  .productDescription {
    margin-bottom: vars.$nodeSpacing;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .productPrice {
    font-size: inherit;
    height: initial;
    line-height: 22px;
    margin-top: 5px;
    p {
      top: -30px;
      font-size: 0.8rem;
    }
  }
  .productImage,
  .adultContentPic {
    width: 100%;
    height: 87px;
    margin-top: 5px;

    @media screen and (min-width: vars.$medium) {
      height: 142px;
    }
  }
  &:hover,
  &.selected {
    @media screen and (min-width: vars.$extra-large) {
      .quickView {
        display: none;
      }
      .productImage img {
        transform: none;
      }
      .goToPdp {
        opacity: 1;
      }
    }
  }

  .goToPdp {
    display: none;
    top: 45px;
    right: 13px;
    width: 15px;
    @media screen and (min-width: vars.$extra-large) {
      display: flex;
    }
  }
}

.marketplaceLayout {
  display: grid;
  grid-template-areas:
    'ptimage'
    'ptadultswitch'
    'ptname'
    'ptdesc'
    'ptprice';
  grid-template-columns: auto;
  grid-template-rows: max-content max-content max-content auto max-content max-content;
  padding: 16px 8px;
  row-gap: 0;
  position: relative;
  text-decoration: none;
  // margin-bottom: $gutter;
  color: var(--fill-black-hover, #555);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: TT Prosto Sans Condensed;
  font-stretch: condensed;
  font-style: normal;

  @media screen and (min-width: vars.$medium) {
    padding: 24px 16px 16px 16px;
  }

  .quickView {
    display: none;
  }

  .productName {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-bottom: 8px;
  }

  .productShop {
    .productShopName {
      color: colors.$secondary-color;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.2px;
    }
  }
  .productDescription {
    color: var(--fill-black-default, #9b9b9b);
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    /* autoprefixer: ignore next */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 60px;
    margin-bottom: 18px;
  }
  .productPrice {
    font-stretch: condensed;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
    height: initial;
    margin: 0px;
    // padding-bottom: 16px;
    // border-bottom: 1px solid #e2e2e2;

    p {
      font-size: 0.8rem;
      position: absolute;
      top: -13px;
      left: calc(100% + 8px);
      margin: 0;
    }

    @media screen and (min-width: vars.$medium) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .productImage,
  .adultContentPic {
    width: 100%;
    height: 90px;
    margin-bottom: 16px;

    @media screen and (min-width: vars.$medium) {
      height: 120px;
    }
  }
  &:hover,
  &.selected {
    @media screen and (min-width: vars.$extra-large) {
      .quickView {
        display: none;
      }
      .productImage img {
        transform: none;
      }
      .goToPdp {
        opacity: 1;
      }
    }
  }

  .goToPdp {
    display: none;
    top: 45px;
    right: 13px;
    width: 15px;
    @media screen and (min-width: vars.$extra-large) {
      display: flex;
    }
  }
}

.columnLayout {
  display: grid;
  grid-template-areas:
    'ptimage ptshop'
    'ptimage ptname'
    'ptimage ptdesc'
    'ptadultswitch ptprice';
  grid-template-columns: 40% auto;
  grid-template-rows: max-content max-content auto max-content max-content;

  .productName {
    margin-bottom: 0px;
  }

  .productDescription {
    -webkit-line-clamp: 4;
    margin-bottom: 20px;

    @media screen and (min-width: vars.$medium) {
      -webkit-line-clamp: 3;
    }
  }

  .productName {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-stretch: condensed;
    font-weight: 700;
  }

  .wishlistBtn {
    display: none;
    @media screen and (min-width: vars.$extra-large) {
      display: flex;
      left: 14px;
      right: unset;
    }
  }

  .productImage,
  .adultContentPic {
    margin-top: 0;
    height: auto;
    max-height: 148px;
    margin-bottom: 0px;
  }

  .productPrice {
    border-bottom: none;
  }
}

.marketplaceLayout.columnLayout {
  grid-template-areas:
    'ptimage ptname'
    'ptimage ptdesc'
    'ptadultswitch ptprice'
    'ptadultswitch ptshop';
  grid-template-rows: max-content auto max-content max-content;

  .productShop {
    // display: none;
  }

  .toggleAdultContent {
    justify-content: center;
  }
}

.badge {
  top: -10px;
  border-radius: 4px;
  background-color: yellow;
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  font-stretch: condensed;
  font-weight: bold;
  line-height: 22px;

  &ColumnView {
    margin-top: -5px;
  }
}

.discount {
  display: flex;
  align-content: center;
  justify-content: center;
  background: colors.$red;
  color: colors.$white;
  left: 15px;
  width: 48px;
}

.new {
  display: flex;
  align-content: center;
  justify-content: center;
  background: var(--light-blue);
  color: colors.$white;
  left: 15px;
  width: initial;
  padding: 0 4px;
  text-transform: uppercase;
}

.discountAndNew {
  left: 15px;

  .discount {
    width: 46px;
    top: 0;
    line-height: 22px;
  }

  .new {
    width: initial;
    top: 0;
    left: 61px;
    line-height: 22px;
  }
}

.selected:after {
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 10px solid colors.$white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -30px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: -1;
}

.wishlistBtn,
.goToPdp {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  opacity: 0.5;
  outline: none;
  position: absolute;
  right: 14px;
  top: 15px;
  transition: opacity 350ms;

  &:hover {
    color: colors.$primary-color;
  }

  @media screen and (min-width: vars.$extra-large) {
    opacity: 0;
    &:hover,
    &:active,
    &:focus {
      opacity: 1 !important;
    }
  }

  &.wishlistSelectedBtn {
    color: colors.$error;
    opacity: 1;
  }
}

.productImage,
.adultContentPic {
  grid-area: ptimage;
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 87px;
  justify-content: center;
  object-fit: contain;
  overflow: hidden;
  width: 120px;

  @media screen and (min-width: vars.$medium) {
    height: 150px;
  }

  @media screen and (min-width: vars.$extra-large) {
    width: 100%;
    margin-bottom: 5px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    transition: transform 0.2s linear;
  }
}

.quickView {
  background: #f4f9fd;
  color: colors.$primary-color;
  height: 30px;
  margin: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  row-gap: vars.$nodeSpacing;
  position: absolute;
  opacity: 0;
  width: calc(100% - 30px);
  margin-bottom: -100px;
  text-decoration: none;
  transition: opacity 0.2s linear;
}

.productName {
  grid-area: ptname;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: colors.$dark-grey;
  color: colors.$dark-text;
  display: -webkit-box;
  font-size: 1rem;
  font-stretch: condensed;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: vars.$extra-large) {
    margin-bottom: 5px;
  }
}

// .productDesc {
//   /* autoprefixer: ignore next */
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 2;
//   color: $secondary-text;
//   display: -webkit-box;
//   font-size: 0.75rem;
//   height: 36px;
//   line-height: 18px;
//   margin-bottom: 5px;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.productPrice {
  grid-area: ptprice;
  font-size: 1.4rem;
  display: flex;
  color: colors.$dark-text;
  align-self: flex-end;
  height: 60px;
  align-items: flex-end;

  @media screen and (min-width: vars.$extra-large) {
    font-size: 1.5rem;
    line-height: 27px;
    margin-top: calc(vars.$gutter + 5px);
    height: auto;

    &Currency {
      font-size: 1.2rem;
      margin-top: -4px;
    }
  }
}

.priceComponent {
  color: colors.$default-text;
  font-stretch: condensed;
  font-weight: bold;
  position: relative;
  line-height: inherit;
  display: flex;

  p {
    color: colors.$secondary-text;

    &:before {
      border-color: colors.$secondary-text;
    }
  }
}

.productShop {
  grid-area: ptshop;
  line-height: 22px;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;

  .productShopName {
    color: colors.$secondary-color;
    overflow: hidden;
    font-stretch: condensed;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
  }
}

// .title {
//   color: $default-text;
//   font-size: 1.5rem;
//   line-height: 1.8rem;
//   font-stretch: condensed;
//   font-weight: bold;
//   margin-bottom: $nodeSpacing;
//   margin-top: 0;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

.productDescription {
  grid-area: ptdesc;
  color: colors.$default-text;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: colors.$secondary-text;
  line-height: 18px;

  @media screen and (min-width: vars.$medium) {
    // height: 134px;
    -webkit-line-clamp: 3;
  }

  * {
    font-weight: normal;
    font-size: 1rem;
    font-stretch: normal;
  }

  br {
    display: none;
  }
}

.productClothing {
  .productImage,
  .adultContentPic {
    height: 135px;
    width: 148px;
    max-width: fit-content;
    margin: auto;
    margin-bottom: 16px;

    @media screen and (min-width: vars.$medium) {
      height: 192px;
      width: 198px;
    }

    @media screen and (min-width: vars.$extra-large) {
      height: 192px;
      width: 190px;
    }
  }

  .productName {
    @media screen and (min-width: vars.$medium) {
      font-size: 16px;
    }
    @media screen and (min-width: vars.$extra-large) {
      -webkit-line-clamp: 1;
      height: initial;
    }
  }

  .productDescription {
    display: none;
  }
}

.shortProductName {
  -webkit-line-clamp: 1;
  height: initial;
}

.toggleAdultContent {
  grid-area: ptadultswitch;
  font-stretch: condensed;
  color: colors.$primary-color;
  display: flex;
  align-items: center;
  gap: 8px;
}

.adultContentPic,
.oldLayout .adultContentPic {
  color: colors.$primary-color;
  width: 100%;
  height: 87px;

  @media screen and (min-width: vars.$medium) {
    height: 120px;
  }
}

.marketplaceLayout .adultContentPic {
  @media screen and (min-width: vars.$medium) {
    height: 98px;
  }
}
